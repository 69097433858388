import { Select } from "@magnetic/select";
import { Text } from "@magnetic/text";
import React from 'react';
import { Organization } from "../../../helpers/pre-authorization-transformer";

type OrganizationSelector = {
  organizations: Array<Organization>;
  selectedOrganization: Organization | undefined;
  onChange: (Organization) => void;
};

const OrganizationSelector = ({ organizations, selectedOrganization, onChange }: OrganizationSelector) => {
  const organizationOptions = organizations
                              .map((org) => ({ label: org.name, value: org.name }))
                              .sort((a, b) => a.value.localeCompare(b.value));

  return (
    <>
      <Text size={"p3"} weight={"semi-bold"} style={{ marginBottom: "10px", marginTop: "20px" }}>Select an organization</Text>
      <div style={{ marginBottom: "5px", width: "50%" }}>
        <Select options={organizationOptions} onChange={onChange} value={selectedOrganization?.name} />
      </div>
      <Text size={"p4"} style={{ marginBottom: "20px" }}>
        You’re providing access to this organization. Organizations that are currently providing access will not be visible.
      </Text>
    </>
  );
};

export default OrganizationSelector;
