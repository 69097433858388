import { Flex, Text } from '@magnetic/core';
import { Divider } from "@magnetic/divider";
import { Drawer } from '@magnetic/drawer';
import DrawerContent from './drawer-content';
import { Tag } from "@magnetic/tag";
import { Scope } from '../../../../helpers/models';
import ScopesTable from '../../../consent/ScopesTable';
import React, { ReactElement } from 'react';

type ScopesProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  availableScopes: Scope[];
  scopes: string[];
  setScopesHandler: (scopes: string[]) => void;
  applicationName: string;
  addScopeButton: ReactElement;
  children: ReactElement;
};

const ScopesDrawer = ({ isOpen, toggleIsOpen, addScopeButton, children, availableScopes, scopes, setScopesHandler, applicationName }: ScopesProps) => {
  return (
    <>
      <Flex gap='xl' align='center' style={{ marginBottom: '20px', marginTop: '-10px' }}>
        <Text>Scopes are permissions that users grant to apps, specifying the data or actions the apps can access on their behalf.</Text>
      </Flex>
      {
        scopes.map((scope, key) => <Tag key={key} style={{ marginRight: "10px", marginBottom: "10px" }}>{scope}</Tag>)
      }
      <Flex>
        {addScopeButton}
      </Flex>
      <Drawer isOpen={isOpen} onClose={toggleIsOpen} size="md">
        <Flex
          direction="vertical"
          gap={4}
        >
          <Drawer.Heading>
            Add access scopes
          </Drawer.Heading>
          <Text
            color="light"
            size="p3"
          >
            It's best to select as few scopes as possible for your app's functionality.
          </Text>
        </Flex>
        <Drawer.Content>
          <DrawerContent availableScopes={availableScopes}
            scopes={scopes}
            setScopesHandler={setScopesHandler} />
        </Drawer.Content>
        <Drawer.Footer>
          { children }
        </Drawer.Footer>
      </Drawer>
      <Divider />
      {scopes && <ScopesTable applicationName={applicationName} applicationScopes={availableScopes.filter((scope) => scopes.includes(scope.name))} />}
    </>

  );
};

export default ScopesDrawer;
