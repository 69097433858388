import { Flex, Heading, Text } from '@magnetic/core';
import { Divider } from "@magnetic/divider";
import { Input } from '@magnetic/input';
import { TextArea } from "@magnetic/text-area";
import React from 'react';

type BasicInfoProps = {
  applicationName: string;
  setApplicationName: (name: string) => void;
  redirectUris: string;
  setRedirectUris: (redirectUris: string) => void;
};

const BasicInfo = ({ applicationName, setApplicationName, redirectUris, setRedirectUris }: BasicInfoProps) => {
  return (
    <>
      <Flex gap='xl' align='center' style={{ marginBottom: '20px', marginTop: '-10px' }}>
        <Text>Give your app a unique name and list of redirect URIs.</Text>
      </Flex>
      <Flex gap='xl' align='center' justify={'space-between'}>
        <Flex direction="vertical" style={{ width: "30%" }}>
          <Heading size="sub-section">Name<span style={{ color: "var(--negative-text-default)" }}>*</span></Heading>
          <Text size='p4' color='light'>What shall we call its?</Text>
        </Flex>
        <Flex direction="vertical" gap="md" style={{ width: "60%" }}>
          <Input
            value={applicationName}
            label="Name"
            name="name"
            placeholder="My app"
            onChange={(event) => setApplicationName(event.target.value)}
            required
            hiddenLabel
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex gap='xl' justify={'space-between'}>
        <Flex direction="vertical" style={{ width: "30%" }}>
          <Heading size="sub-section">Redirect URLs<span style={{ color: "var(--negative-text-default)" }}>*</span></Heading>
          <Text size='p4' color='light'>Users will be redirected to this location after granting access to your app</Text>
        </Flex>
        <Flex direction="vertical" gap="md" style={{ width: "60%" }}>
          <TextArea
            value={redirectUris}
            label="Redirect URI"
            name="redirect_uris"
            placeholder="https://application.com/callback&#10;https://application.com/oauth2/redirect"
            onChange={(event) => setRedirectUris(event.target.value)}
            required
            hiddenLabel
          />
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};

export default BasicInfo;
