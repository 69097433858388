import { CopyToClipboard } from "@magnetic/copy-to-clipboard";
import { Container, Flex, Heading } from '@magnetic/core';
import { Text } from "@magnetic/text";
import { Button } from '@magnetic/button';
import RenewApplicationSecretModal from "../renew-application-secret-modal";
import { fetchApi } from '../../../helpers/api/fetch-api';
import { Application } from "../../../helpers/models";
import { ApiBasePathContext } from "../../app";
import { ToastType } from "./index";
import React, { useState, useContext }  from 'react';

type AuthorizationInfoProps = {
  application: Application;
  setDisplayToastHandler: (toastType: ToastType) => void;
}

const AuthorizationInfo = ({ application, setDisplayToastHandler }: AuthorizationInfoProps) => {
  const apiBasePath = useContext(ApiBasePathContext);
  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";

  const [showRenewSecretModal, setShowRenewSecretModal] = useState<boolean>(false);
  const [applicationSecret, setApplicationSecret] = useState<string>("");

  const renewApplicationSecret = async () => {
    setDisplayToastHandler(ToastType.None);

    const authOptions = {
      auth: {
        csrfToken: csrf
      }
    };
    const [response, errors] = await fetchApi<Application>("POST", `${apiBasePath}/${application.id}/secret`, undefined, authOptions);

    if (response) {
      setApplicationSecret(response.data.secret);
      setShowRenewSecretModal(true);
    }

    if (errors.length > 0) {
      setDisplayToastHandler(ToastType.Error);
    }
  };

  return (
    <>
      <Container style={{ marginBottom: '30px' }}>
        <Heading size="section">Authorization</Heading>
        <Text size="p2" color='light' style={{ marginBottom: "20px" }}>This app uses OAuth to integrate with a user’s Meraki account</Text>
        <Container.Divider />
        <Flex gap='xl' align='center' justify={'space-between'}>
          <Flex direction="vertical" style={{width: "40%"}}>
            <Heading size="sub-section">Client ID</Heading>
            <Text size='p4' color='light'>A unique identifier for your app, used to initiate OAuth</Text>
          </Flex>
          <CopyToClipboard
            label=''
            contained
            value={application.uid}
            style={{width: "50%"}}
          />
        </Flex>
        <Container.Divider />
        <Flex gap='xl' align='center' justify={'space-between'}>
          <Flex direction="vertical" style={{width: "40%"}} >
            <Heading size="sub-section">Client Secret</Heading>
            <Text size='p4' color='light'>A confidential text string known only to the client (your app) and the authorization server (Meraki), used to initiate OAuth. </Text>
            <Text size='p4' color='light' style={{ marginTop: "20px" }}>This should be stored securely, and never be hard-coded directly into source code. Cisco does not store or retrieve client secrets. If lost, you may generate a new secret.  </Text>
          </Flex>
          <Flex direction="vertical" align='flex-start' gap='sm' style={{width: "50%"}}>
            <Container.Well  style={{ width: "100%", padding: "12px" }}>
              <Text>•••• •••••••• •••• •••• ••••••••</Text>
            </Container.Well>
            <Button
              kind='secondary'
              size='md'
              onClick={async () => {renewApplicationSecret();}}
            >
              Generate new secret
            </Button>
          </Flex>
        </Flex>
      </Container>
      {
        showRenewSecretModal && (
          <RenewApplicationSecretModal 
            clientSecret={applicationSecret} 
            closeModalHandler={() => {
              setShowRenewSecretModal(false);
            }} 
          />
        )
      }
    </>
  );
};

export default AuthorizationInfo;