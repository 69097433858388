import { Container, Heading } from '@magnetic/core';
import { Application, Scope } from '../../../helpers/models';
import EditScopes from "../create-application/scopes/edit-scopes";
import React from 'react';

type AccessInfoProps = {
  availableScopes: Scope[];
  application: Application;
  setApplicationHandler: (application: Application) => void;
}

const AccessInfo = ({ application, setApplicationHandler, availableScopes }: AccessInfoProps) => (
  <Container style={{ marginBottom: '30px' }}>
    <Heading style={{ marginBottom: '10px' }}>Access Scopes</Heading>
    <EditScopes
      availableScopes={availableScopes}
      scopes={application.scopes}
      setScopesHandler={(scopes) => setApplicationHandler({ ...application, scopes: scopes })}
      applicationName={application.name}
    />
  </Container>
);

export default AccessInfo;