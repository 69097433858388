import { Flex } from '@magnetic/core';
import { Tag } from "@magnetic/tag";
import { Text } from "@magnetic/text";
import { Collapse } from "@magnetic/collapse";
import { Divider } from "@magnetic/divider";
import React, { useState } from 'react';

type SummaryProps = {
  applicationName: string;
  redirectUris: string[];
  applicationScopes: string[];
};

const Summary = ({ applicationName, redirectUris, applicationScopes }: SummaryProps) => {
  const [openBasicInfo, setOpenBasicInfo] = useState<boolean>(true);
  const [openScopes, setOpenScopes] = useState<boolean>(true);
  const toggleOpenBasicInfo = () => setOpenBasicInfo(!openBasicInfo);
  const toggleOpenScopes = () => setOpenScopes(!openScopes);

  return (
    <>
      <Flex gap='xl' align='center' style={{ marginBottom: '20px', marginTop: '-10px' }}>
        <Text>Review your selections below</Text>
      </Flex>
      <Collapse.Group>
        <Collapse
          onClick={toggleOpenBasicInfo}
          open={openBasicInfo}
        >
          <Collapse.Heading>
            Basic Info
          </Collapse.Heading>
          <Collapse.Panel>
            <Flex>
              <Text weight={'semi-bold'} style={{ width: "30%" }}>Name</Text>
              <Flex style={{ width: "60%" }}>
                <Text>{applicationName}</Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex>
              <Text weight={'semi-bold'} style={{ width: "30%" }}>Redirect URLs</Text>
              <Flex direction="vertical" gap="md" style={{ width: "60%" }}>
                {
                  redirectUris.map((uri, key) => (<Text key={key}>{uri}</Text>))
                }
              </Flex>
            </Flex>
          </Collapse.Panel>
        </Collapse>
        <Collapse
          onClick={toggleOpenScopes}
          open={openScopes}
        >
          <Collapse.Heading>
            Access Scopes
          </Collapse.Heading>
          <Collapse.Panel>
            {
              applicationScopes.map((scope, key) => <Tag key={key} style={{ marginRight: "10px", marginBottom: "10px" }}>{scope}</Tag>)
            }
          </Collapse.Panel>
        </Collapse>
      </Collapse.Group>
    </>
  );
};

export default Summary;