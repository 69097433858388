import ReactOnRails from 'react-on-rails';
import ConsentPage from '../src/components/consent';
import LoginPage from '../src/components/login';
import App from '../src/components/app';

// Client side rendered components
ReactOnRails.register({
  ConsentPage
});
ReactOnRails.register({
  LoginPage
});
ReactOnRails.register({
  App
});
