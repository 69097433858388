import { Button } from '@magnetic/button';
import { Flex, Heading } from '@magnetic/core';
import React, { useContext, useEffect, useState } from 'react';
import DeleteApplicationModal from '../delete-application-modal';
import AccessInfo from './access-info';
import AuthorizationInfo from './authorization-info';
import BasicInfo from './basic-info';
import { Application, Scope, initialApplicationObject } from '../../../helpers/models';
import { Tabs } from "@magnetic/tabs";
import { Breadcrumb } from "@magnetic/breadcrumb";
import { fetchApi } from '../../../helpers/api/fetch-api';
import { Toast } from '@magnetic/notification';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiBasePathContext } from '../../app';

enum DetailTabs {
  BasicInfo = "Basic Info",
  Authorization = "Authorization",
  AccessScopes = "Access Scopes"
}

export enum ToastType {
  None = "none",
  Success = "success",
  Error = "error"
}

const ApplicationDetails = () => {
  const apiBasePath = useContext(ApiBasePathContext);

  const { applicationId } = useParams<{ applicationId: string }>();
  const navigate = useNavigate();

  const [showApplicationDeleteModal, setShowApplicationDeleteModal] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<DetailTabs>(DetailTabs.BasicInfo);
  const [displayToast, setDisplayToast] = useState<ToastType>(ToastType.None);

  const [originalApplication, setOriginalApplication] = useState<Application>(initialApplicationObject);
  const [application, setApplication] = useState<Application>(initialApplicationObject);
  const [availableScopes, setAvailableScopes] = useState<Scope[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const scopesHaveNotChanged = (application.scopes.length === originalApplication.scopes.length && application.scopes.every((scope) => originalApplication.scopes.includes(scope)));
  const nameHasNotChanged = (application.name.length === 0 || application.name === originalApplication.name);
  const redirectUrisHaveNotChanged = (application.redirectUris.length === 0 || application.redirectUris === originalApplication.redirectUris);

  const noChangesMade = application && scopesHaveNotChanged && nameHasNotChanged && redirectUrisHaveNotChanged;

  const fetchApplication = async () => {
    setIsLoading(true);

    const [response, errors] = await fetchApi<Application>("GET", `${apiBasePath}/${applicationId}`);

    setOriginalApplication(response.data);
    setApplication(response.data);
    setIsLoading(false);

    if (errors.length > 0) {
      setDisplayToast(ToastType.Error);
    }
  };

  const fetchScopes = async () => {
    const [response, errors] = await fetchApi<Scope[]>("GET", "/oauth/scopes");

    if (response) {
      setAvailableScopes(response.data);
    }

    if (errors.length > 0) {
      setDisplayToast(ToastType.Error);
    }
  };

  const saveApplication = async () => {
    setDisplayToast(ToastType.None);

    const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";

    const authOptions = {
      auth: {
        csrfToken: csrf
      }
    };

    const [response, errors] = await fetchApi<Application>(
      "PUT",
      `${apiBasePath}/${application.id}`,
      {
        doorkeeper_application: {
          name: application.name,
          scopes: application.scopes.join(" "),
          redirect_uri: application.redirectUris,
        }
      },
      authOptions
    );

    if (response) {
      setDisplayToast(ToastType.Success);
    }

    if (errors.length > 0) {
      setDisplayToast(ToastType.Error);
    }

    await fetchApplication();
  };

  useEffect(() => {
    const fetchData =  async () => {
      setIsLoading(true);

      await fetchApplication();
      await fetchScopes();

      setIsLoading(false);
    };

    fetchData();
  }, []);

  const DetailsHeading = () => (
    <Flex direction={"vertical"}>
      <Flex
        direction="vertical"
        gap={4}
      >
        <Breadcrumb>
          <Breadcrumb.Link href={apiBasePath}>
            My Applications
          </Breadcrumb.Link>
        </Breadcrumb>
      </Flex>
      <Heading size="primary">{originalApplication.name}</Heading>
    </Flex>
  );

  return (
    <>
      {
        displayToast === "success" && (
          <Toast
            toastList={[
              {
                children: 'Your changes were successfully saved.',
                key: "success-toast",
                status: 'information',
                title: 'Changes saved.'
              }
            ]}
            onRemove={() => setDisplayToast(ToastType.None)}
          />
        )
      }
      {
        displayToast === "error" && (
          <Toast
            toastList={[
              {
                children: "Error while editing application. Changes were not saved.",
                key: "error-toast",
                status: 'warning',
                title: 'Error occurred.'
              }
            ]}
            onRemove={() => setDisplayToast(ToastType.None)}
          />
        )
      }
      { !isLoading && (
          <>
            <Flex justify={'space-between'}>
              <DetailsHeading />
              <Button.Group style={{ marginBottom: '20px' }}>
                <Button kind="secondary" type="submit" destructive onClick={() => setShowApplicationDeleteModal(true)}>
                  Delete
                </Button>
              </Button.Group>
            </Flex>

            <Tabs kind="primary" style={{ marginBottom: "20px" }}>
              <Tabs.Link selected={selectedTab === DetailTabs.BasicInfo} onClick={() => setSelectedTab(DetailTabs.BasicInfo)}>Basic Info</Tabs.Link>
              <Tabs.Link selected={selectedTab === DetailTabs.Authorization} onClick={() => setSelectedTab(DetailTabs.Authorization)}>Authorization</Tabs.Link>
              <Tabs.Link selected={selectedTab === DetailTabs.AccessScopes} onClick={() => setSelectedTab(DetailTabs.AccessScopes)}>Access Scopes</Tabs.Link>
            </Tabs>

            {
              selectedTab === DetailTabs.BasicInfo && (
                <BasicInfo
                  application={application}
                  setApplicationHandler={setApplication}
                />
              )
            }

            {
              selectedTab === DetailTabs.Authorization && (
                <AuthorizationInfo 
                  application={application} 
                  setDisplayToastHandler={setDisplayToast}
                />
              )
            }

            {
              selectedTab === DetailTabs.AccessScopes && (
                <AccessInfo
                  application={application}
                  availableScopes={availableScopes}
                  setApplicationHandler={setApplication}
                />
              )
            }
            <Button.Group>
              <Button
                kind="tertiary"
                // essentially reloads the page
                onClick={() => navigate(0)}
              >
                Cancel
              </Button>
              <Button
                kind="secondary"
                onClick={saveApplication}
                disabled={noChangesMade}
              >
                Save
              </Button>
            </Button.Group>
          </>
        )
      }

      <DeleteApplicationModal
        applicationId={application.id}
        shouldDisplayModal={showApplicationDeleteModal}
        closeModalHandler={() => setShowApplicationDeleteModal(false)}
        onCancelHandler={() => setShowApplicationDeleteModal(false)}
        onConfirmHandler={() => {
          setShowApplicationDeleteModal(false);
          navigate('/');
        }}
      />
    </>
  );
};

export default ApplicationDetails;
