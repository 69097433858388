import { Button } from "@magnetic/button";
import { Plus } from '@magnetic/icons';
import { Scope } from '../../../../helpers/models';
import ScopesDrawer from './scopes-drawer';
import React, { useState } from 'react';

type ScopesProps = {
  availableScopes: Scope[];
  scopes: string[];
  setScopesHandler: (scopes: string[]) => void;
  applicationName: string;
};

const EditScopes = ({applicationName, availableScopes, scopes, setScopesHandler }: ScopesProps) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const toggleOpenDrawer = () => setOpenDrawer(!openDrawer);

  const EditAccessScopes = () => (
    <Button
      icon={<Plus size={16} weight="bold" />}
      kind="tertiary"
      onClick={toggleOpenDrawer}
    >
      Add access scopes
    </Button>
  );

  return (
    <ScopesDrawer
      addScopeButton={<EditAccessScopes />}
      applicationName={applicationName}
      isOpen={openDrawer}
      toggleIsOpen={toggleOpenDrawer}
      availableScopes={availableScopes}
      scopes={scopes}
      setScopesHandler={setScopesHandler}
    >
      <Button kind="tertiary" onClick={toggleOpenDrawer}>
        Close
      </Button>
    </ScopesDrawer>
  );
};

export default EditScopes;
