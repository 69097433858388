import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { createContext } from 'react';
import ApplicationsTable from './applications/applications-table';
import CreateApplicationWizard from './applications/create-application';
import ApplicationDetails from './applications/application-details';
import { Scope } from '../helpers/models';

type AppProps = {
  apiBasePath: string;
  canCreateNewApplication: boolean;
  maxNumberOfApplications: number;
  availableScopes: Scope[];
}

export const ApiBasePathContext = createContext(null);

const App = ({
  apiBasePath,
  canCreateNewApplication,
  maxNumberOfApplications,
}: AppProps) => {
  return (
    <ApiBasePathContext.Provider value={apiBasePath}>
      <BrowserRouter basename={apiBasePath} >
        <Routes>
          <Route path="/" element={<ApplicationsTable canCreateNewApplication={canCreateNewApplication} maxNumberOfApplications={maxNumberOfApplications} />} />
          <Route path="/new" element={<CreateApplicationWizard/>} />
          <Route path="/:applicationId" element={<ApplicationDetails/>} />
        </Routes>
      </BrowserRouter>
    </ApiBasePathContext.Provider>
  );
};

export default App;