import React from 'react';
import * as Sentry from '@sentry/react';
import ConsentScreenHeader from './ConsentScreenHeader';
import AuthorizationApproval from './AuthorizationApproval';
import { Modal } from "@magnetic/modal";
import { PreAuthorization, transformPreAuthForView } from '../../helpers/pre-authorization-transformer';

type SentryConfig = {
  dsn: string;
  environment: string;
  release: string;
}

type ConsentPageProps = PreAuthorization & {
  preAuthorization: PreAuthorization;
  apiBasePath: string;
  sentryConfig: SentryConfig;
};

const ConsentPage = ({ preAuthorization, apiBasePath, sentryConfig }: ConsentPageProps) => {
  const transformedPreAuth = transformPreAuthForView(preAuthorization);

  Sentry.init(sentryConfig);

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred.</p>}>
      <Modal size={"lg"} isOpen={true}>
        <ConsentScreenHeader applicationName={transformedPreAuth.applicationName} />
        <AuthorizationApproval preAuthorizationData={transformedPreAuth} apiBasePath={apiBasePath}/>
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

export default ConsentPage;
