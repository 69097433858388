export type Scope = {
  name: string;
  description: string;
  category: "telemetry" | "configure" | null;
  access: "read" | "write" | null;
  feature: string | null;
  visible?: boolean;
  group: "dashboard" | "network" | "iot" | "sm";
  featureCategory: string;
}

type ApplicationOwner = {
  email: string;
}

export type Application = {
  name: string;
  id: string;
  uid: string;
  redirectUris: string;
  scopes: string[];
  owner: ApplicationOwner;
  secret?: string;
}

export const initialApplicationObject = {
  id: "",
  name: "",
  uid: "",
  redirectUris: "",
  scopes: [],
  owner: {
    email: ""
  },
  secret: ""
};

export type RedirectURI = string;
export type AuthorizeURL = string;
