import { Scope } from "../../../../helpers/models";
import { Text, Flex } from "@magnetic/core";
import { Table } from "@magnetic/table";
import { Card } from "@magnetic/card";
import { Link } from "@magnetic/link";
import ScopesSelection from "./scopes-selection";
import React from "react";
import { ArrowSquareOut } from "@magnetic/icons";

type DescriptionCardProps = {
  description: string;
};

const DescriptionCard = ({ description }: DescriptionCardProps) => {
  return (
    <Card>
      <Text>{description}</Text>
      <Link
        href="https://developer.cisco.com/meraki/"
        rightIcon={<ArrowSquareOut />}
      >
        Meraki OAuth scopes
      </Link>
    </Card>
  );
};

const RadioHeader = () => {
  return (
    <Flex gap={16}>
      <Text weight="semi-bold">None</Text>
      <Text weight="semi-bold">Read</Text>
      <Text weight="semi-bold">Read/Write</Text>
    </Flex>
  );
};

const TableColumn = (header: string, scopes: string[], setScopesHandler: (scopes: string[]) => void) => {
  return (
    [
      {
        header: header,
        accessorKey: "name",
        enableSorting: false,
        size: 270,
      },
      {
        accessorKey: "radio",
        enableSorting: false,
        size: 200,
        header: () => <RadioHeader />,
        cell: (tableRecord) => <ScopesSelection
          strippedScope={tableRecord.row.original.id}
          scopes={scopes}
          setScopesHandler={setScopesHandler}
        />,
      }
    ]
  );
};

type TableRow = {
  id: string;
  name: string;
  subComponent: JSX.Element;
}

type DrawerContentProps = {
  availableScopes: Scope[];
  scopes: string[];
  setScopesHandler: (scopes: string[]) => void;
};

const DrawerContent = ({ availableScopes, scopes, setScopesHandler }: DrawerContentProps) => {
  const strippedScopes = availableScopes.reduce((accumulator, currentScope) => {
    const group = currentScope.group;
    const featureCategory = currentScope.featureCategory;

    if (!accumulator[group].featureCategories.has(featureCategory)) {
      const row: TableRow = {
        id: featureCategory,
        name: featureCategory,
        subComponent: <DescriptionCard description={currentScope.description} />,
      };
      accumulator[group].rows.push(row);
      accumulator[group].featureCategories.add(featureCategory);
    };
    return accumulator;
  }, {
    dashboard: { rows: [], featureCategories: new Set() },
    network: { rows: [], featureCategories: new Set() },
    iot: { rows: [], featureCategories: new Set() },
    sm: { rows: [], featureCategories: new Set() },
  });

  return (
    <Flex direction="vertical" gap="lg">
      <Table
        columns={TableColumn("Dashboard", scopes, setScopesHandler)}
        data={strippedScopes["dashboard"].rows}
        expandable
        distributedColumns
      />
      <Table
        columns={TableColumn("Network", scopes, setScopesHandler)}
        data={strippedScopes["network"].rows}
        expandable
        distributedColumns
      />
      <Table
        columns={TableColumn("IoT", scopes, setScopesHandler)}
        data={strippedScopes["iot"].rows}
        expandable
        distributedColumns
      />
      <Table
        columns={TableColumn("Endpoint Management (SM)", scopes, setScopesHandler)}
        data={strippedScopes["sm"].rows}
        expandable
        distributedColumns
      />
    </Flex>
  );
};

export default DrawerContent;