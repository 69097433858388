import React from 'react';
import { Heading } from "@magnetic/heading";

type ConsentScreenHeaderProps = {
  applicationName: string;
};

const ConsentScreenHeader = ({ applicationName }: ConsentScreenHeaderProps) => {
  return (
    <Heading size="primary">
      {applicationName} wants to access your Meraki organization
    </Heading>
  );
};

export default ConsentScreenHeader;