
import { Stepper, useStepper } from "@magnetic/stepper";
import { fetchApi } from '../../../helpers/api/fetch-api';
import { Application, Scope } from "../../../helpers/models";
import BasicInfo from "./basic-info";
import Summary from "./summary";
import React, { useState, useEffect, useContext } from 'react';
import { Notification } from "@magnetic/notification";
import { Breadcrumb } from "@magnetic/breadcrumb";
import EditScopes from "./scopes/edit-scopes";
import { useNavigate } from "react-router-dom";
import CreateApplicationModal from "../create-application-modal";
import { ApiBasePathContext } from "../../app";

const CreateApplicationWizard = () => {
  const apiBasePath = useContext(ApiBasePathContext);

  const { currentStep, onGoBack, onGoNextAndComplete, onGoToCompletedStep } = useStepper(3);

  const [applicationName, setApplicationName] = useState<string>("");
  const [availableScopes, setAvailableScopes] = useState<Scope[]>([]);
  const [redirectUris, setRedirectUris] = useState<string>("");
  const [scopes, setScopes] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [showApplicationCreatedModal, setShowApplicationCreatedModal] = useState<boolean>(false);
  const [createdApplication, setCreatedApplication] = useState<Application | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchScopes = async () => {
      const [response, errors] = await fetchApi<Scope[]>("GET", "/oauth/scopes");

      if (response) {
        setAvailableScopes(response.data);
      }
      if (errors) {
        setSubmitErrors(errors);
      }
    };

    fetchScopes();
  }, []);

  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";

  const onCollapse = () => setIsExpanded(false);
  const onExpand = () => setIsExpanded(true);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Link href={apiBasePath}>
          My Applications
        </Breadcrumb.Link>
      </Breadcrumb>
      {submitErrors.length > 0 &&
        <Notification status={"negative"} onClose={() => { setSubmitErrors([]); }}>
          {submitErrors}
        </Notification>
      }
      <Stepper workflowTitle="Create Application">
        <Stepper.LeftPanel
          isExpanded={isExpanded}
          onCollapse={onCollapse}
          onExpand={onExpand}
        >
          <Stepper.Step
            completed={currentStep > 1}
            active={currentStep === 1}
            expanded={isExpanded}
            onClick={() => { onGoToCompletedStep(1); }}
            stepNumber={1}
            title="Basic Info"
          />
          <Stepper.Step
            active={currentStep === 2}
            disabled={currentStep < 2}
            completed={currentStep > 2}
            expanded={isExpanded}
            onClick={() => { onGoToCompletedStep(2); }}
            stepNumber={2}
            title="Scopes"
          />
          <Stepper.Step
            active={currentStep === 3}
            disabled={currentStep < 3}
            expanded={isExpanded}
            stepNumber={3}
            title="Summary"
          />
        </Stepper.LeftPanel>
        {currentStep === 1 &&
          <Stepper.RightPanel
            content={
              <BasicInfo
                applicationName={applicationName}
                setApplicationName={setApplicationName}
                setRedirectUris={setRedirectUris}
                redirectUris={redirectUris}
              />
            }
            primaryAction={onGoNextAndComplete}
            primaryCta={"Next"}
            secondaryCta={"Cancel"}
            secondaryAction={() => { history.back(); }}
            stepTitle="Basic Info"
            isPrimaryActionDisabled={applicationName.length === 0 || redirectUris.length === 0}
            isTertiaryActionAvailable={false}
            tertiaryAction={() => { }}
          />
        }
        {currentStep === 2 &&
          <Stepper.RightPanel
            content={
              <EditScopes
                availableScopes={availableScopes}
                scopes={scopes}
                setScopesHandler={setScopes}
                applicationName={applicationName}
              />
            }
            primaryAction={onGoNextAndComplete}
            secondaryAction={() => { history.back(); }}
            primaryCta={"Next"}
            secondaryCta={"Cancel"}
            stepTitle="Access Scopes"
            isPrimaryActionDisabled={false}
            isTertiaryActionAvailable
            tertiaryAction={onGoBack}
          />
        }
        {currentStep === 3 &&
          <Stepper.RightPanel
            content={
              <Summary
                applicationName={applicationName}
                redirectUris={redirectUris.split(/\s+/)}
                applicationScopes={scopes}
              />
            }
            primaryAction={async () => {
              const authOptions = {
                auth: {
                  csrfToken: csrf
                }
              };

              const [response, errors] = await fetchApi<Application>(
                "POST",
                apiBasePath,
                {
                  doorkeeper_application: {
                    name: applicationName,
                    redirect_uri: redirectUris,
                    scopes: scopes.join(" "),
                  }
                },
                authOptions
              );

              if (response) {
                setCreatedApplication(response.data);
                setShowApplicationCreatedModal(true);
              }

              if (errors) {
                setSubmitErrors(errors);
              }
            }}
            secondaryAction={() => { location.href = apiBasePath; }}
            primaryCta={"Submit"}
            secondaryCta={"Cancel"}
            stepTitle="Summary"
            isPrimaryActionDisabled={false}
            isTertiaryActionAvailable
            tertiaryAction={onGoBack}
          />
        }
      </Stepper>
      {
        showApplicationCreatedModal && (
          <CreateApplicationModal clientId={createdApplication.uid} clientSecret={createdApplication.secret} closeModalHandler={() => {
            navigate(`/${createdApplication.id}`);
            setShowApplicationCreatedModal(false);
          }} />
        )
      }
    </>
  );
};

export default CreateApplicationWizard;
