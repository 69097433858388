import { Flex } from '@magnetic/flex';
import { Empty } from "@magnetic/icons";
import { Text } from "@magnetic/text";
import { Button } from "@magnetic/button";
import { Link } from '@magnetic/core';
import React, { useContext } from 'react';
import { ApiBasePathContext } from '../../app';

const NoApps = () => {
  const apiBasePath = useContext(ApiBasePathContext);

  return (
    <Flex direction="vertical" gap="md">
      <Flex justify="center" >
        <Empty.Info />
      </Flex>
      <Flex justify="center" >
        <Text size="p2" weight="semi-bold">No applications yet</Text>
      </Flex>
      <Flex justify="center">
        <Text>Create your first application</Text>
      </Flex>
      <Flex justify="center" style={{ marginTop: "20px" }} >
        <Link href={`${apiBasePath}/new`}>
          <Button value="Create application" kind="primary">
            Create application
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default NoApps;
