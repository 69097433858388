import React, { SyntheticEvent, useEffect, useState, useContext } from 'react';

import { Button } from '@magnetic/button';
import { Container, Link } from '@magnetic/core';
import { Dropdown } from '@magnetic/dropdown';
import { Flex } from '@magnetic/flex';
import { DotsThree } from '@magnetic/icons';
import { Search } from '@magnetic/search';
import { Text } from "@magnetic/text";
import { MdsTableColumn, Table } from '@magnetic/table';
import DeleteApplicationModal from '../delete-application-modal';
import { Application } from '../../../helpers/models';
import { fetchApi } from '../../../helpers/api/fetch-api';
import { Heading } from "@magnetic/heading";
import { ApiBasePathContext } from '../../app';
import NoApps from './no-apps';

type TableRow = Pick<Application, 'id' | 'name'> & { ownerEmail: string }

type ApplicationsTableProps = {
  canCreateNewApplication: boolean;
  maxNumberOfApplications: number;
}

const ApplicationsTable = ({ canCreateNewApplication, maxNumberOfApplications }: ApplicationsTableProps) => {
  const apiBasePath = useContext(ApiBasePathContext);

  const [filter, setFilter] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [applicationToDelete, setApplicationToDelete] = useState("");
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchApplications = async () => {
    setIsLoading(true);
    const [response] = await fetchApi<TableRow[]>("GET", `${apiBasePath}`);

    setApplications(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const tableData: TableRow[] = applications.map((application) => ({
    id: application.id,
    name: application.name,
    ownerEmail: application.owner.email
  }));

  const tableColumns: MdsTableColumn<TableRow>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (tableRecord) => {
        const url = `${apiBasePath}/${tableRecord.row.original.id}`;
        const nameForRow = tableRecord.renderValue() as TableRow["name"];

        return (<Link href={url}>{nameForRow}</Link>);
      },
    },
    {
      header: 'Owner',
      accessorKey: 'ownerEmail',
    },
    {
      header: '',
      id: 'actions',
      cell: (tableRecord) => {
        return (
          <Flex justify={'flex-end'}>
            <Dropdown icon={<DotsThree />} kind="tertiary">
              <Link href={`${apiBasePath}/${tableRecord.row.original.id}`}><Dropdown.Item label="Edit" /></Link>
              <Dropdown.Item
                label="Remove"
                destructive={true}
                onClick={() => {
                  setApplicationToDelete(tableRecord.row.original.id);
                  setShowDeleteModal(true);
                }}
              />
            </Dropdown>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
    <Flex direction="vertical" style={{ marginBottom: "20px" }}>
      <Heading size="primary">My Applications</Heading>
      <Text>Manage your existing integrations or create new ones</Text>
    </Flex>
    {
      applications.length === 0 && !isLoading && <NoApps />
    }
    {
      applications.length > 0 && !isLoading && (
        <Container>
          <Flex justify="space-between" style={{ marginBottom: "20px" }}>
            <Search
              style={{ width: "300px" }}
              onChange={(e: SyntheticEvent) => setFilter((e.target as HTMLInputElement).value)}
              placeholder="Search"
              label="Search"
              options={[]}
            />
            <Flex gap="sm" align="center">
              <Text size={"p4"} style={{ margin: "8px" }} className="maxApplicationsText">
                Maximum of {maxNumberOfApplications} applications
              </Text>
              <Link href={`${apiBasePath}/new`}>
                <Button value="Create application" kind="primary" disabled={!canCreateNewApplication}>
                  Create application
                </Button>
              </Link>
            </Flex>
          </Flex>
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={false}
            globalFilter={filter}
            onGlobalFilterChange={setFilter}
          />
        </Container>
      )
    }
    <DeleteApplicationModal
      applicationId={applicationToDelete}
      shouldDisplayModal={showDeleteModal}
      closeModalHandler={() => setShowDeleteModal(false)}
      onCancelHandler={() => setShowDeleteModal(false)}
      onConfirmHandler={() => {
        setShowDeleteModal(false);
        fetchApplications();
      }}
    />
    </>
  );
};

export default ApplicationsTable;
