import { Options, apiRequest, isApiError } from "@cisco-meraki/dashboard-api-tools";

/* eslint-disable @typescript-eslint/no-unused-vars */
const httpMethods = ["get", "post", "put", "delete", "options", "GET", "POST", "PUT", "DELETE", "OPTIONS"] as const;
export type HTTPMethod = typeof httpMethods[number];

type ApiResponse<ResponseSchema> = {
  data: ResponseSchema;
  ok: boolean;
  statusCode: number;
  statusText: string;
}

type ApiResponseMetadata<ResponseSchema> = [ApiResponse<ResponseSchema> | undefined, string[] | undefined];

export async function fetchApi <ResponseSchema>(
  method: HTTPMethod,
  url: string,
  body?: Record<string, unknown> | undefined,
  options?: Options
): Promise<ApiResponseMetadata<ResponseSchema>> {
  let response: ApiResponse<ResponseSchema> | undefined;
  const errors: string[] = [];

  try {
    const responseFromApi = await apiRequest<ResponseSchema>(method, url, body, options);

    response = responseFromApi;
  } catch (badResponse) {
    if (isApiError(badResponse)) {
      errors.push(...badResponse.errors);
    } else {
      errors.push("Could not parse errors from response");
    }
  }

  return [response, errors];
}
