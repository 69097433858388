import React from 'react';
import { Text } from '@magnetic/text';
import { Scope } from '../../../helpers/models';

interface ScopeCategories {
  configure: Scope[];
  telemetry: Scope[];
}

type GroupedScopes = {
  write: ScopeCategories;
  read: ScopeCategories;
};

type ScopesTableProps = {
  applicationName: string;
  applicationScopes: Scope[];
};


const ScopesTable = ({ applicationName, applicationScopes }: ScopesTableProps) => {
  const scopesGroupedForTable = applicationScopes.reduce(
    (groupedScopes: GroupedScopes, scope: Scope): GroupedScopes => {
      groupedScopes[scope.access][scope.category].push(scope);

      return groupedScopes;
    },
    {
      write: {
        configure: [],
        telemetry: [],
      },
      read: {
        configure: [],
        telemetry: [],
      },
    }
  );

  const scopesShouldBeGrouped = (scope1: Scope, scope2: Scope) => {
    return scope1.feature === scope2.feature && scope1.access === scope2.access;
  };

  const groupedScopesByAccess = (access: Scope['access']): [Scope[], Scope[], Scope[]] => {
    const containsBothCategories = (scope: Scope) =>
      scopesGroupedForTable[access].telemetry.map((x) => x.feature).includes(scope.feature);
    const configureAndTelemetry = scopesGroupedForTable[access].configure.filter(containsBothCategories);

    const containsSingleCategory = (scope: Scope) =>
      !configureAndTelemetry.some((scopeWithBothFeatureAndAccess) =>
        scopesShouldBeGrouped(scopeWithBothFeatureAndAccess, scope)
      );
    const configureOnly = scopesGroupedForTable[access].configure.filter(containsSingleCategory);
    const telemetryOnly = scopesGroupedForTable[access].telemetry.filter(containsSingleCategory);

    return [configureAndTelemetry, configureOnly, telemetryOnly];
  };

  const [writeConfigureAndTelemetry, writeConfigureOnly, writeTelemetryOnly] = groupedScopesByAccess('write');
  const [readConfigureAndTelemetry, readConfigureOnly, readTelemetryOnly] = groupedScopesByAccess('read');

  return (
    <>
      {
        (scopesGroupedForTable.write.configure.length > 0 || scopesGroupedForTable.write.telemetry.length > 0) &&
        <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>{applicationName} requests read/write access to:</Text>
      }
      {
        writeConfigureAndTelemetry.length > 0 &&
        <>
          <Text>Configuration and Telemetry features applicable to:</Text>
          <ul>
            {
              writeConfigureAndTelemetry.map((scope, key) => <li key={`${key}-write-both`}><Text>{scope.description}</Text></li>)
            }
          </ul>
        </>
      }
      {
        writeConfigureOnly.length > 0 &&
        <>
          <Text>Configuration features applicable to:</Text>
          <ul>
            {
              writeConfigureOnly.map((scope, key) => <li key={`${key}-write-config`}><Text>{scope.description}</Text></li>)
            }
          </ul>
        </>
      }
      {
        writeTelemetryOnly.length > 0 &&
        <>
          <Text>Telemetry features applicable to:</Text>
          <ul>
            {
              writeTelemetryOnly.map((scope, key) => <li key={`${key}-write-telemtry`}><Text>{scope.description}</Text></li>)
            }
          </ul>
        </>
      }

      {
        (scopesGroupedForTable.read.configure.length > 0 || scopesGroupedForTable.read.telemetry.length > 0) &&
        <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>{applicationName} requests read access to:</Text>
      }
      {
        readConfigureAndTelemetry.length > 0 &&
        <>
          <Text>Configuration and Telemetry features applicable to:</Text>
          <ul>
            {
              readConfigureAndTelemetry.map((scope, key) => <li key={`${key}-read-both`}><Text>{scope.description}</Text></li>)
            }
          </ul>
        </>
      }
      {
        readConfigureOnly.length > 0 &&
        <>
          <Text>Configuration features applicable to:</Text>
          <ul>
            {
              readConfigureOnly.map((scope, key) => <li key={`${key}-read-config`}><Text>{scope.description}</Text></li>)
            }
          </ul>
        </>
      }
      {
        readTelemetryOnly.length > 0 &&
        <>
          <Text>Telemetry features applicable to:</Text>
          <ul>
            {
              readTelemetryOnly.map((scope, key) => <li key={`${key}-read-telemtry`}><Text>{scope.description}</Text></li>)
            }
          </ul>
        </>
      }
    </>
  );
};

export default ScopesTable;