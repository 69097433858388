
import React from 'react';
import { Flex, Heading, Link } from '@magnetic/core';
import { Button } from '@magnetic/button';
import { Text } from '@magnetic/text';
import CiscoLogo from "./cisco-logo.png";
import { ArrowSquareOut } from "@magnetic/icons";
import "./index.scss";

type LoginPageProps = {
  loginPath: string;
}

const LoginPage = ({ loginPath }: LoginPageProps) => {
  const csrf = document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";

  const DocumentationLink = ({ href, text }) => (
    <Link rightIcon={<ArrowSquareOut />} style={{ width: "fit-content", color: "#0D8BD4" }} href={href} target="_blank">{text}</Link>
  );

  return (
    <Flex>
      <div className="left-panel-container">
        <Flex className="text-on-image" direction={"vertical"} gap={"md"}>
          <Text style={{ color: "white", fontSize: "48px", lineHeight: "normal" }}>Supercharge Your Application with Meraki APIs</Text>
          <Text style={{ color: "white" }} size={"p4"}>Get started building your app integration today! Explore our developer guides and ecosystem partner resources to get started.</Text>
          <DocumentationLink href={"https://developer.cisco.com/meraki/api-v1/getting-started/"} text={"OAuth developer guide"} />
          <DocumentationLink href={"https://developer.cisco.com/meraki/api-v1/"} text={"API reference"} />
          <DocumentationLink href={"https://ciscopartnerjourneys.com/en/us/meraki/api"} text={"Guide to building integrations with Meraki"} />
          <DocumentationLink href={"https://ciscopartnerjourneys.com/en/us/meraki/meraki"} text={"Become a Cisco Meraki Ecosystem Partner"} />
        </Flex>
      </div>
      <Flex className="right-panel-container" direction={"vertical"} justify={"space-between"}>
        <Flex justify={"flex-start"} gap={"sm"} align={"center"}>
          <img src={CiscoLogo} width={"50px"} />
          <div>Integrate</div>
        </Flex>
        <Flex direction={"vertical"} gap={"sm"}>
          <Heading size={"h2"}>Sign in to create and manage your integrations</Heading>
          <Text size={"p4"} style={{ marginBottom: "20px" }}>Get started on your journey of integrating with Cisco</Text>
          <form action={loginPath} method="post">
            <input autoComplete="off" name="authenticity_token" type="hidden" value={csrf} />
            <Button kind="primary" type="submit" style={{ width: "100%", justifyContent: "center" }}>
              Sign In
            </Button>
          </form>
        </Flex>
        <Flex direction={"horizontal"} gap={"lg"} justify={"flex-start"}>
          <Link href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html" target="_blank">
            Terms
          </Link>
          <Link href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank">
            Policy
          </Link>
          <Text color="light">&copy;{new Date().getFullYear()} Cisco Systems, Inc.</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
