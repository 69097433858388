import { Scope } from "./models";

export type Organization = {
  id: string;
  name: string;
}

export type PreAuthorization = {
  client_id: string;
  state: string;
  response_type: string;
  response_mode: string;
  redirect_uri: string;
  client_name: string;
  scope: Scope[];
  code_challenge: string;
  code_challenge_method: string;
  organizations: Array<Organization>;
}

export type PreAuthorizationForView = {
  applicationId: string;
  applicationName: string;
  responseType: string;
  responseMode: string;
  state: string;
  redirectUri: string;
  scopes: Scope[];
  codeChallenge: string;
  codeChallengeMethod: string;
  organizations: Array<Organization>;
}

export const transformPreAuthForView = (preAuthorization: PreAuthorization): PreAuthorizationForView => (
  {
    applicationId: preAuthorization.client_id,
    applicationName: preAuthorization.client_name,
    state: preAuthorization.state,
    responseType: preAuthorization.response_type,
    responseMode: preAuthorization.response_mode,
    redirectUri: preAuthorization.redirect_uri,
    scopes: preAuthorization.scope,
    codeChallenge: preAuthorization.code_challenge,
    codeChallengeMethod: preAuthorization.code_challenge_method,
    organizations: preAuthorization.organizations,
  }
);
