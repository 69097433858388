import { Button } from '@magnetic/button';
import { Checkbox } from "@magnetic/checkbox";
import { CopyToClipboard } from "@magnetic/copy-to-clipboard";
import { Flex, Heading } from '@magnetic/core';
import { Modal } from '@magnetic/modal';
import { Notification } from "@magnetic/notification";
import { Text } from "@magnetic/text";
import { Positive } from '@magnetic/icons';
import React, { useState } from 'react';

type RenewApplicationSecretModalProps = {
  closeModalHandler: () => void;
  clientSecret: string;
}

const RenewApplicationSecretModal = ({ closeModalHandler, clientSecret }: RenewApplicationSecretModalProps) => {
  const [clientSecretStored, setClientSecretStored] = useState<boolean>(false);

  const toggleCheckBox = () => setClientSecretStored(!clientSecretStored);

  return (
    <Modal isOpen={true}>
      <Flex align="center" style={{ marginBottom: '20px' }}>
        <Positive style={{ marginRight: '10px' }}/>
        <Heading>Client Secret</Heading>
      </Flex>

      <Text size='p3' color='light' style={{ marginBottom: "20px" }}>Below is the information on your newly generated client secret</Text>

      <Heading size="sub-section">Client Secret</Heading>
      <Text size='p3' color='light'>A confidential text string known only to the client (your app) and the authorization server (Meraki), used to initiate OAuth. </Text>
      <CopyToClipboard label='' value={clientSecret} contained style={{ width: "100% "}}/>

      <Notification status={"warning"} style={{ marginTop: "20px", marginBottom: "30px" }}>
        This should be stored securely, and never be hard-coded directly into source code. Cisco does not store or retrieve client secrets.
      </Notification>

      <Checkbox checked={clientSecretStored} onClick={toggleCheckBox} style={{ marginBottom: "30px" }} name="secret_stored">
        { /* Hacky since Checkbox doesn't have a required prop */ }
        I've stored my Client Secret<span style={{ color: "var(--negative-text-default)" }}>*</span>
      </Checkbox>

      <Flex justify="center">
        <Button disabled={!clientSecretStored} value="Done" type="submit" kind="primary" onClick={closeModalHandler}>Done</Button>
      </Flex>
    </Modal>
  );
};

export default RenewApplicationSecretModal;
