import { Radio } from "@magnetic/radio";
import React from "react";

type ScopesSelectionProps = {
  strippedScope: string;
  scopes: string[];
  setScopesHandler: (scopes: string[]) => void;
};

const ScopesSelection = ({ strippedScope, scopes, setScopesHandler }: ScopesSelectionProps) => {
  // TODO update the scope parsing logic below after we have the new scopes API endpoint
  let selectedScope = "none";
  const readScope = strippedScope.concat(":").concat("read");
  const writeScope = strippedScope.concat(":").concat("write");
  if (scopes.includes(readScope)) {
    selectedScope = "read";
  }
  if (scopes.includes(readScope) && scopes.includes(writeScope)) {
    selectedScope = "write";
  }

  const handleChangeScopes = (scopesToAdd, scopesToRemove) => {
    let newScopes = scopes.concat(scopesToAdd.filter(addScope => !scopes.includes(addScope)));
    newScopes = newScopes.filter((scope) => !scopesToRemove.includes(scope));
    newScopes.sort();

    setScopesHandler(newScopes);
  };

  return (<Radio
    onChange={(event) => {
      if (event.target.value === "none") {
        handleChangeScopes([], [readScope, writeScope]);
      } else if (event.target.value === "read") {
        handleChangeScopes([readScope], [writeScope]);
      } else {
        handleChangeScopes([readScope, writeScope], []);
      }
    }}
    direction={"horizontal"}
    options={[
      {
        key: "none",
        label: "",
        value: "none"
      },
      {
        key: "read",
        label: "",
        value: "read"
      },
      {
        key: "write",
        label: "",
        value: "write"
      }
    ]}
    value={selectedScope}
  />);
};

export default ScopesSelection;